

html, header {
  width: 100%;
  max-width:800px;
  min-width:200px;
  margin:auto;
}

body {
  width:100%;
  max-width:800px;
  min-width:200px;
  margin:auto;
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

h1 {
  font-size:20px;
  font-weight:bold;
  padding-left:10px;
  width:90%;
}

:root {
  --min-screen-width: min(100%, 600px);
}

canvas {
  display:inline-block;
}

img {
  pointer-events: none;
}

.nickname {
  vertical-align: middle;
  font-size:12px;
  display:inline-block;
  overflow:hidden;
  font-weight:bold;
  text-overflow:ellipsis;
}

.nickname_c {
  vertical-align: middle;
  font-size:12px;
  display:inline-block;
  overflow:hidden;
  font-weight:bold;
  text-overflow:ellipsis;
  margin-left:3px;
}

.username {
  vertical-align: middle;
  font-size:12px;
  display:inline-block;
  overflow:hidden;
  text-overflow:ellipsis;
}

.id_thumbnail_pic {
  border-color:lightgrey;
  border-width:thin;
  border-radius: 8px;
  display:inline-block;
  height:20px;
  width:20px;
  vertical-align: middle;
  image-orientation: from-image;
  object-fit:cover;
}

.id_thumbnail_pic_button {
  border-color:lightgrey;
  border-width:thin;
  border-radius: 8px;
  display:inline-block;
  height:30px;
  width:30px;
  vertical-align: middle;
  image-orientation: from-image;
  object-fit:cover;
  margin-bottom:5px;
}

.id_thumbnail_post {
  width:inherit;
  max-width:400px;
  margin:0px auto 0px auto;
  padding-left:5px;
  padding-top:50px;
  box-sizing: border-box;
}

.id_thumbnail{
  width:inherit;
  max-width:400px;
  margin:0px auto 5px auto;
}
.id_thumbnail_new{
  width: 96%;
  max-width: 600px;
  margin: 0px auto 5px auto;
}

.id_thumbnail_p {
  width:inherit;
  max-width:400px;
  margin:0px auto 5px auto;
  text-align: left;
  padding-left: 15%;
  text-overflow:hidden;
}

.post_top_row {
  width: inherit;
  max-width: 400px;
  margin: 0px auto 0px auto;
  height: 50px;
  line-height: 50px;
}

.post_top_row_fixed {
  width: 100%;
  max-width: 400px;
  margin: 0px auto 20px auto;
  height: 50px;
  line-height: 50px;
  position: fixed;
  display: block;
  background-color: white;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.post_top_row_write {
  width: inherit;
  max-width: 400px;
  margin: 0px auto 0px auto;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  line-height: 60px;
  top: 0px;
  position: sticky;
  z-index: 1;
  background-color:white;
  border-bottom: 0.5px solid rgb(226, 226, 226);
}


.other_top_row {
  width: 100%;
  max-width: 600px;
  position: fixed;
  display: block;
  background-color: white;
  z-index: 1;
  left:50%;
  transform:translateX(-50%);
}

.post_top_row_comment {
  width:inherit;
  max-width:400px;
}

.lists_row_post {
  width:inherit;
  max-width:400px;
  margin:0px auto 0px auto;
  padding-left:5px;
  box-sizing: border-box;
  scrollbar-width:none;
}
.post_thumbnail {
  display:block;
  position:relative;
  width:100%;
  height:100%;
}
.rating {
  font-size:18px;
  color: #FFDD20;
  font-weight: bold;
  background-color: rgba(0,0,0,0.5);
  position:absolute;
  right:3%;
  bottom:3%;
  padding: 2% 5% 2% 5%;
  border-radius:15px;
  display:inline-block;
}

.rating_post {
  font-size: 22px;
  color: #FFDD20;
  font-weight: bold;
  float: right;
  margin-right: 10px;
  padding: 5px 0px 5px 0px;
}

.post_thumbnail_pic {
  width: 100%;
  height: 100%;
  border-color: gray;
  border-width: thin;
  border-radius: 14px;
  position: absolute;
  object-fit: cover;
}

.private_lock {
  margin-left:4px;
  color:#595959;
  font-size:12px;
}

.review_target_preview {
  font-size: 16px;
  width: 100%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 18px;
  margin: 6px 0px 6px 0px;
  text-align: center;
}
.review_text_preview {
  font-size: 12px;
  width: 100%;
  height: 43px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}
.posts_preview_columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 3px;
  min-width:200px;
  white-space: nowrap;
  text-overflow:clip;
  margin-bottom:80px;
}
.posts_preview_columns_new {
  min-width:200px;
  max-width:600px;
  white-space: nowrap;
  text-overflow:clip;
  margin: 20px auto 80px auto;
}
.posts_preview_columns_t {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 3px;
  min-width:200px;
  white-space: nowrap;
  text-overflow:clip;
  margin-bottom:65px;
}

.posts_preview_columns_t_new {
  min-width:200px;
  max-width:600px;
  white-space: nowrap;
  text-overflow:clip;
  margin: 20px auto 80px auto;
}

.posts_preview_columns_p {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 3px;
  min-width:200px;
  white-space: nowrap;
  text-overflow:clip;
  margin-bottom:80px;
  margin-top:20px;
}
.posts_preview {
  width: 96%;
  min-width: 125px;
  margin: 0px auto 0px auto;
  padding-top: 15px;
  box-sizing: border-box;
  border-bottom: 0.5px solid rgb(226, 226, 226);
}

.posts_preview_unchecked {
  width: 96%;
  min-width: 125px;
  margin: 0px auto 0px auto;
  padding-top: 15px;
  box-sizing: border-box;
  border-bottom: 0.5px solid rgb(226, 226, 226);
  background-color: #F2FFFF
}

.posts_preview_p {
  width: 96%;
  min-width: 125px;
  margin: 0px auto 20px auto;
  padding: 0px;
}

.top_row {
  height:40px;
  line-height:40px;
  width:100%;
  max-width:600px;
  position:fixed;
  display:block;
  background-color:white;
  z-index:1;
  border:0px;
  border-bottom:1px;
  border-color:lightgrey;
  border-style:solid;
  left:50%;
  transform: translateX(-50%);
}

.top_panel {
  height:195px;
  width:100%;
  max-width:600px;
  vertical-align:top;
  display:inline-block;
  margin-top:50px;
  position:relative;
  padding:0px;
  left: 50%;
  transform: translateX(-50%);
}
.profile_picture {
  height:90px;
  width:90px;
  margin-left:10px;
  border-radius:20px;
  display: inline-block;
  image-orientation: from-image;
  object-fit:cover;
}

#originalpp {
  height:90px;
  width:90px;
  object-fit:cover;
  text-align:center;
  margin:0px auto 0px auto;
}


.profile_owner {
  font-size:14px;
  width:75%;
  padding:6px 0px 6px 0px;
  margin:auto;
  display:inline-block;
  text-align:center;
  vertical-align:top;
}

.profile_owner2 {
  font-size:14px;
  width:100%;
  padding:6px 0px 6px 0px;
  margin:auto;
  display:inline-block;
  text-align:center;
  vertical-align:top;
}

header {
  width:100%;
  overflow:visible;
}

.lists_navigation {
  height:auto;
  width:100%;
  max-width:600px;
  border-top:1px;
  border-bottom: 1px;
  border-right: 0px;
  border-left: 0px;
  border-color:lightgrey;
  border-style:solid;
  margin: 0px auto 8px auto;
  background-color:white;
  position:sticky;
  position:-webkit-sticky;
  top:40px;
  z-index:1;
}
.id_panel {
  width:60%;
  max-width:900px;
  min-width:110px;
  height:100%;
  margin:0px 0px 0px 5px;
  display:inline-block;
  padding:2px;
  vertical-align:top;
}
.name {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}
.introduction {
  font-size: 14px;
  height: 65px;
  display: block;
  overflow: hidden;
}
.lists_row{
  vertical-align:middle;
  width:90%;
  margin:8px 10px 8px 10px;
  overflow-x: auto;
  text-overflow:clip;
  vertical-align:top;
  scrollbar-width: none;
}

.lists_row::-webkit-scrollbar {
  display: none;
} 

.eVUGYy {
  -webkit-animation: 0.5s cVKHEA;
  animation: 0.5s cVKHEA;
  width:80%;
  display:inline-block;
}

.jrNyDJ {
  -webkit-animation: 0.5s cVKHEA;
  animation: 0.5s cVKHEA;
  display:inline-block;
}

.list_tag {
  padding: 4px 8px 0px 8px;
  color: grey;
  font-size: 14px;
  font-weight: bold;
  border-radius: 14px;
  margin-right: 5px;
  height: 20px;
  line-height: 18px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  max-width:300px;
  border-color: grey;
  border-width: 0.5px;
  border-style: solid;
}

.list_tag_profile {
  padding: 4px 8px 0px 8px;
  color:grey;
  font-size:14px;
  font-weight:bold;
  border-radius:14px;
  margin-right:7px;
  margin-bottom:5px;
  height:20px;
  display:inline-block;
  text-align:center;
  vertical-align:middle;
  border-color: grey;
  border-width: 0.5px;
  border-style: solid;
}



.recommend {
  background-color: #FFDD20;
  color: white;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 8px 0px 8px;
  border: 0px;
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 5px;
  width: 95px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
}

.modify {
  background-color:darkgrey;
  color:white;
  border-radius:14px;
  font-size:12px;
  font-weight:bold;
  padding: 0px 8px 0px 8px;
  border:0px;
  display: inline-block;
  position:absolute;
  right:10px;
  bottom:27px;
  width:90px;
  height:20px;
  text-align:center;
}

.subscribe {
  background-color: #FFDD20;
  color: white;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 8px 0px 8px;
  border: 0px;
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 37px;
  width: 95px;
  height: 30px;
  text-align: center;
}


.RHS-icons {
  width:8%;
  padding: 6px 5px 0px 0px;
  margin:0px;
  float: right;
  text-align:right;
}
.goback {
  display: inline-block;
  width:10%;
  padding: 6px 0px 6px 5px;
  text-align:left;
}
.goback_w {
  display: inline-block;
  width:20%;
  padding: 6px 0px 6px 5px;
  text-align:left;
}
.gonext {
  display: inline-block;
  width:20%;
  margin-left:55%;
  padding: 6px 0px 6px 5px;
  text-align:right;
  color: #FFDD20;
  font-weight:bold;
}
.write_direction {
  font-size:18px;
}
.goback_comment {
  display: inline-block;
  width:10%;
  padding: 6px 0px 6px 5px;
  left:0px;
}
footer {
  height:50px;
}
.bottom_navigator{
  position:fixed;
  height:80px;
  width:100%;
  max-width:600px;
  bottom:0px;
  background-color:#f6f6f6;
  left: 50%;
  transform: translateX(-50%);
}
.bottom_icon{
  display:inline-block;
  width:20%;
  text-align:center;
  padding: 15px 0px 0px 0px;
}

.bottom_icon_i{
  font-size:30px;
}

#bottom_star {
  color: #FFDD20; 
}

.review_post_target {
  width:80%;
  display:inline-block;
  font-size:18px;
  font-weight:bold;
  overflow:hidden;
  text-overflow:ellipsis;
  padding: 5px 0px 5px 5px;
}
.stars {
  text-align:right;
  color: #FFDD20;
  vertical-align:top;
  padding:5px;
  float: right; 
}
.full_picture_post {
  margin-top:10px;
  margin-bottom:10px;
  margin-left:auto;
  margin-right:auto;
  position: relative;
  max-width:400px;
}
.full_picture {
  width:100%;
  height:100%;
  max-width:400px;
  position:absolute;
  margin-right:auto;
  margin-left:auto;
  object-fit:cover;
}
.review_details{
  font-size: 14px;
  padding: 0px 7px 0px 7px;
  margin: 10px auto 10px auto;
  width: inherit;
  max-width: 400px;
  position: block;
  box-sizing: border-box;
  white-space: pre-line;
  line-height: 20px;
  text-align: justify;
  word-break: keep-all;
}
.timestamp {
  font-size:14px;
  font-style:italic;
  margin: 30px auto 0px auto;
  width:inherit;
  max-width:400px;
  text-align:right;
  padding-right:10px;

}
.post_bottom {
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding-top: 10px;
  position: fixed;
  display: block;
  bottom: 0px;
  background-color: white;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}
.post_bottom_column {
  width:31%;
  display:inline-block;
  text-align:center;
  font-size:16px;
  padding:0px;
  margin-right:5px;
}
.post_recommend {
  width:100%;
  background-color: #FFDD20;
  font-weight:bold;  
  color: white;
  border:0px;
  padding:8px;
  border-radius:12px;
  text-align:center;
  font-size:16px;
}
.post_save {
  width: 100%;
  background-color: #E2E2E2;
  font-weight: bold;
  color: black;
  border: 0px;
  padding: 8px;
  border-radius: 12px;
  text-align: center;
  font-size:16px;
}
.post_share {
  width:100%;
  background-color: #f6f6f6;
  font-weight:bold;  
  color: black;
  border:0px;
  padding:8px;
  border-radius:12px;
  text-align:center;
  font-size:16px;
}
a {
  color:black;
  text-decoration:none;
}

.ralert_profile, .ualert_profile{
  width:100%;
  max-width:800px;
  height:25px;
  font-size:14px;
  text-align:center;
  vertical-align:middle;
  padding:10px 0px 5px 0px;
  position:absolute;
  color: black;
  font-weight: bold;
  background-color: #D3F4FB;
  z-index:10;
}

.ralert_post, .ualert_post {
  width: 100%;
  max-width: 400px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  margin: 0px auto 5px auto;
  padding: 10px 0px 5px 0px;
  display: block;
  color: black;
  font-weight: bold;
  background-color: #D3F4FB;
  z-index: 99;
}

.lazy-container {
  width:100%;
  position: relative;
  display:block;
  height: 0px;
  padding-bottom:100%;
  background-clip: content-box;
  box-shadow: inset 0 0 0 200px #f6f6f6;
  border-radius:20px;
  object-fit:cover;
 }

 .lazy-container2 {
  width:100%;
  position: relative;
  display:block;
  height: 0px;
  padding-bottom:100%;
  background-clip: content-box;
  box-shadow: inset 0 0 0 200px #f6f6f6;
  object-fit:cover;
 }

.lazy-container-post {
  width:100%;
  max-width:400px;
  position: relative;
  display:block;
  height: 0px;
  padding-bottom:100%;
  background-clip: content-box;
  box-shadow: inset 0 0 0 1000px #f6f6f6;
 }

.ualert_profile_before{
  width:100%;
  max-width:800px;
  height:25px;
  font-size:14px;
  text-align:center;
  vertical-align:middle;
  padding:10px 0px 5px 0px;
  position:absolute;
  color: black;
  font-weight: bold;
  background-color: white;
  z-index:10;
}

.url_profile{
  width:60%;
  height:20px;
  text-align:left;
  position:absolute;
  left:10px;
  font-size:12px;
  vertical-align:middle;
  position:absolute;
  color: black;
  font-family:sans-serif;
  background-color: white;
  z-index:10;
  display:inline-block;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.url_copy_button, .url_copy_button_post{
  background-color:grey;
  padding: 4px 8px 0px 8px;
  color:white;
  font-size:12px;
  font-weight:bold;
  border-radius:14px;
  position:absolute;
  right:10px;
  height:20px;
  display:inline-block;
  text-align:right;
  vertical-align:middle;
  z-index:11;
}

.login_forms {
  width:100%;
  max-width:400px;
  vertical-align:middle;
  text-align:center;
  font-size:14px;
  margin:20% auto 0px auto;
}

.login_forms_pop {
  width: 100%;
  max-width: 400px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  margin: 200px auto 0px auto;
}

.b_explanation {
  width:80%;
  max-width:400px;
  white-space: normal;
  text-align:center;
  font-weight:bold;
  margin:0px auto 20px auto;
  word-break: keep-all;
  font-size:14px;
}


.b_explanation_write {
  width:80%;
  max-width:400px;
  white-space: normal;
  text-align:center;
  font-size:20px;
  font-weight:bold;
  margin:20px auto 40px auto;
  word-break: keep-all;
}

.text_input {
  width:80%;
  max-width:400px;
  height:30px;
  text-align:left;
  margin:0px auto 10px auto;
  font-size:14px;
}

.login_button {
  width:80%;
  max-width:400px;
  height:30px;
  text-align:center;
  font-weight:bold;
  border-width:0px;
  margin:0px auto 10px auto;
  color:white;
  background-color:#FFDD20;
  font-size:14px;
  border-radius:10px
}

.additional_info {
  width:80%;
  max-width:400px;
  height:30px;
  text-align:center;
  font-weight:bold;
  border-width:0px;
  margin:0px auto 10px auto;
  font-size:14px;
  border-radius:10px;
}

.login_banner {
  width:80%;
  max-width:400px;
  text-align:center;
  border-width:0px;
  margin:0px auto 10px auto;
  object-fit:contain;
  display:block;
  position:relative;
}
.login_banner_container{
  position: relative;
  display:block;
  height: 0px;
  padding-bottom:35%;

}

.ualert_post_before{
  width:100%;
  max-width:400px;
  height:25px;
  font-size:14px;
  text-align:center;
  vertical-align:middle;
  padding:10px 0px 5px 0px;
  position:absolute;
  color: black;
  font-weight: bold;
  background-color: white;
  z-index:10;
}

.top_banner_container {
  width: 100%;
  max-width: 600px;
  position: relative;
  padding-bottom: 10%;
  text-align: left;
  margin: 10px auto 0px auto;
  height:30px
}
.top_banner {
  position:absolute;
  width:40%;
}
.noti_container {
  width: 10%;
  display: inline-block;
  float: right;
  text-align: center;
  padding-top: 15px;
  padding-right: 5px;
}
.fa-check {
  color:green;
}

.write_forms{
  margin:10px auto 0px auto;
  width:100%;
  max-width:400px;
  box-sizing:border-box;
  padding:15px;
}
.write_category{
  display:inline-block;
  width:30%;
  vertical-align:middle;
  font-weight:bold;
}
.write_content{
  width:65%;
  height:40px;
  line-height:40px;
  vertical-align:middle;
  border-width:0px;
  font-size:14px;
  text-align:center;
  display:inline-block;
  font-family: "Noto Sans",sans-serif;
}
.write_content_a {
  height:40px;
  line-height:40px;
  vertical-align:middle;
  border-width:0px;
  font-size:14px;
  text-align:center;
  display:inline-block;
  font-family: "Noto Sans",sans-serif;
}

.write_content_vis{
  width:65%;
  height:auto;
  vertical-align:middle;
  border-width:0px;
  font-size:14px;
  text-align:center;
  display:inline-block;
  font-family: "Noto Sans",sans-serif;
}

.write_row{
  padding:5px 0px 5px 0px;
  border-width:0px 0px 1px 0px;
  border-color:lightgrey;
  border-style:solid;
}
.write_row_empty{
  padding:5px 0px 5px 0px;
}
.review{
  width:100%;
  height:300px;
  margin:10px auto 0px auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size:14px;
  font-family: "Noto Sans",sans-serif;
  scrollbar-width:none;
}
.review::-webkit-scrollbar {
  display: none;
} 
.review_upload{
  width:90%;
  max-width:400px;
  height:40px;
  text-align:center;
  display:block;
  font-weight:bold;
  border-width:0px;
  margin:0px auto 10px auto;
  color:white;
  background-color:#FFDD20;
  font-size:16px;
  border-radius:5px;
}

#rvub{
  margin-top:20px;
}

#rating {
  font-size:24px;
  color: #FFDD20;
  font-weight: bold;
  text-align: center;
  display:inline-block;
  margin:0px auto 0px auto;
  width:20%;
  vertical-align:middle;
  padding-bottom:5px;
}
.rating_input{
  display:inline-block;
  width:60%;
  text-align:center;
  vertical-align:middle;
}
.rating_btn{
  background-color:white;
  border-width:0px;
  color:#FFDD20;
}
#list_tags_input{
  height:80px;
  line-height:60px;
  scrollbar-width:none;
  word-break:keep-all;
}

.write_content::-webkit-scrollbar {
  display: none;
}
#review_photo{
  text-align:center;
  vertical-align:middle;
  display:none;
  margin:0px auto 0px auto;
}
.photo_label{
  text-align:center;
  vertical-align:middle;
  margin:0px auto 0px auto;
  width:65%;
  display:inline-block;
}
#r_im_preview{
  width:100%;
}

#list_tags_show{
  width:60%;
  border-width:0px;
  height:80px;
  overflow-x: auto;
  text-overflow:clip;
  scrollbar-width: none;
  line-height:60px;
}

#username {
  text-align:center;
  margin:0px auto 0px auto;
  display:inline-block;
}
.smanage {
  background-color: #FFDD20;
  color:white;
  border-radius:14px;
  font-size:12px;
  font-weight:bold;
  padding: 0px 8px 0px 8px;
  border:0px;
  display: inline-block;
  position:absolute;
  right:10px;
  bottom:49px;
  width:90px;
  height:20px;
  text-align:center;
  letter-spacing: -2px;
}

.stats {
  background-color: #FFDD20;
  color:white;
  border-radius:14px;
  font-size:12px;
  font-weight:bold;
  padding: 0px 8px 0px 8px;
  border:0px;
  display: inline-block;
  position:absolute;
  right:10px;
  bottom:71px;
  width:90px;
  height:20px;
  text-align:center;
  letter-spacing: -2px;
}

.slider{
  display:inline-block;
  position:absolute;
  right:10px;
  vertical-align:center;
  background-color:white;
  padding:2px 0px 2px 10px;
}

.more {
  width:10%;
  float:right;
  padding: 6px 5px 6px 5px;
  text-align:right;
}

.sm_options{
  z-index:90;
  width:80%;
  max-width:500px;
  height:100px;
  border-radius:20px;
  position:fixed;
  text-align:center;
  vertical-align:middle;
  margin:0px 0px 0px 0%;
  font-size:14px;
  font-weight:bold;
  background-color:white;
  color:black;
  transform: translate(30%,30%);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sm_options2{
  z-index:90;
  width:80%;
  max-width:500px;
  height:auto;
  border-radius:20px;
  position:fixed;
  text-align:center;
  vertical-align:middle;
  margin:0px 0px 0px 0%;
  font-size:14px;
  font-weight:bold;
  background-color:white;
  color:black;
  transform: translate(30%,30%);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.layer{
  z-index: 89;
  height: 100%;
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  position: fixed;
  text-align: center;
  line-height: 600px;
  background-color: rgba(0,0,0,.65);
}
.sm_button{
  height:33%;
  line-height:220%;
  border-width:0px 0px 1px 0px;
  border-style:solid;
  border-color:lightgrey;
  text-align:center;
  vertical-align:middle;
}

.sm_button_l{
  height:33%;
  line-height:220%;
  border-width:0px 0px 1px 0px;
  border-style:solid;
  border-color:lightgrey;
  text-align:center;
  vertical-align:middle;
  border-bottom:none;
}

.m_banner_container {
  width:100%;
  text-align:center;
}

.m_banner_container_m {
  width:100%;
  text-align:center;
  margin:20px auto 30px auto;
  max-width: 600px;
}

.m_banner{
  width:80%;
}

.roffer{
  margin-top:5px;
  font-weight:bold;
  text-decoration:underline;
  text-align:center;
  color:blue;
}

.sm_top_row {
  width:inherit;
  max-width:800px;
  margin:0px auto 0px auto;
}

.top_ex {
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  max-width: 600px;
  margin: 0px auto 0px auto;
  box-sizing: border-box;
  padding: 10px;
}

#ppupload2{
text-align:center;
vertical-align:middle;
display:none;
margin:0px auto 0px auto;
}

#r_im_preview_2{
  width:100%;
}

#ppupload{
text-align:center;
vertical-align:middle;
display:none;
margin:0px auto 0px auto;
}

#sobutton{
  background-color:#808080;
}
.pm_forms{
  margin:80px auto 0px auto;
  width:100%;
  max-width:400px;
  box-sizing:border-box;
  padding:15px;
}
.pm_label{
  text-align:center;
  margin:0px auto 20px auto;
  width:90px;
  display:block;
}

.pm_label2 {
  text-align:center;
  margin:0px auto 0px auto;
  width:90px;
  display:block;
}
.ppbutton{
  text-align:center;
  margin:0px auto 0px auto;
  width:100%;
  display:inline-block;
  background-color:#0190FE;
  color:white;
  border-radius:5px;
  font-size:13px;
}
.profile_picture2 {
  height:90px;
  width:90px;
  border-radius:20px;
  image-orientation: from-image;
  object-fit:cover;
  text-align:center;
  margin:0px auto 0px auto;
}
.write_hide {
  display:none;
  height:0px;
}

.cat_tag_profile {
  padding: 4px 8px 0px 8px;
  color:grey;
  font-size:14px;
  font-weight:bold;
  border-radius:14px;
  margin-right:5px;
  height:20px;
  display:inline-block;
  text-align:center;
  vertical-align:middle;
  border-color: grey;
  border-width: 0.5px;
  border-style: solid;
}

.cat_tag_profile_l, .list_tag_profile_l {
  padding: 4px 8px 0px 8px;
  color:grey;
  font-size:14px;
  font-weight:bold;
  border-radius:14px;
  margin-right:5px;
  height:20px;
  display:inline-block;
  text-align:center;
  vertical-align:middle;

}

.cat_tag_profile_select {
  background-color:grey;
  padding: 4px 8px 0px 8px;
  color:white;
  font-size:14px;
  font-weight:bold;
  border-radius:14px;
  margin-right:5px;
  height:20px;
  display:inline-block;
  text-align:center;
  vertical-align:middle;
}

.list_tag_profile_select {
  background-color:grey;
  padding: 4px 8px 0px 8px;
  color:white;
  font-size:14px;
  font-weight:bold;
  border-radius:14px;
  margin-right:7px;
  margin-bottom:5px;
  height:20px;
  display:inline-block;
  text-align:center;
  vertical-align:middle;
}

.save_review_write {
  background-color: #FFDD20;
  color: white;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 8px 0px 8px;
  border: 0px;
  display: inline-block;
  width: 48%;
  max-width: 200px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  margin-right: 20px;
}

.save_review_del {
  background-color:#f6f6f6;
  color:grey;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 8px 0px 8px;
  border: 0px;
  display: inline-block;
  width: 48%;
  max-width: 200px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  margin-right: 20px; 
}

.srb_container {
  margin-bottom:10px;
  text-align:center;
  max-width:600px;
}

.rpanel {
  margin-top:10px;
  width: 90px;
  display:inline-block;
  position:absolute;
  bottom:15px;
  left:12px;
  font-size:14px;
  font-weight:bold;
}

#rpop_b, #order_c {
  border-bottom:none;
}

.panel_count{
  position:absolute;
  right:10px;
}

.list_tag_i {
  background-color: #f6f6f6;
  color: grey;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 4px;
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
}
.list_tag_i_select {
  background-color: #FFDD20;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 4px;
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
}

.postrt{
  margin:20px 0px 80px 10px;
  font-size:14px;
  height:100px;
  padding-top:20px;
  vertical-align:bottom;
}

.postrtc{
  display:block;
  height:110px;
  margin:0px auto 100px auto;
  width: inherit;
  max-width: 400px;
}

.postrtc_a{
  display:block;
  height:auto;
  margin:0px auto 0px auto;
  width: inherit;
  max-width: 400px;
  background-color: transparent;
}


.postrtc_l{
  display:block;
  height:55px;
  margin:0px auto 0px auto;
  width: inherit;
  max-width: 400px;
}




#pc1{
  font-weight:bold;
}

.rimp{
  max-width:150px
}

#infoa {
  text-decoration:underline;
  font-weight:bold;
}
.infoaclose{
  float:right;
  margin: 0px 10px 5px 0px;
}
.infoatext{
  width:100%;
  height:500px;
}
.lt_container_row{
  width:95%;
  max-width:95%;
  overflow: scroll;
  text-overflow: hidden;
  white-space: nowrap;
  height:28px;
  scrollbar-width:none;
  margin-top:3px
}

.lt_container_row::-webkit-scrollbar {
  display: none;
}
.comments_open{
  width: 95%;
  max-width: 400px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  margin: 10px auto 20px auto;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  border-color: lightgrey;
  border-radius: 10px;
}
.comments_row{
  height:70%;
  overflow:scroll;
  scrollbar-width:none;
}

.comments_row::-webkit-scrollbar {
  display: none;
} 
.comments_section{
  width:100%;
  margin: 0px auto 0px auto;
  max-width:400px;
  height:100vh;
  position:relative;
  box-sizing: border-box;
  padding: 10px;
}
.comments_input{
  width: 100%;
  height: 50px;
  margin-left: 10px;
  position: absolute;
}
.comments_input_text{
  width: 75%;
  border-width:2px;
  border-style:solid;
  border-color:lightgrey;
  height:40px;
  border-radius:20px;
  display:inline-block;
  vertical-align:top;
}
.comments_input_push{
  width: 18%;
  height:40px;
  border-radius:20px;
  border-width:0px;
  display:inline-block;
  vertical-align:top;
  background-color: #FFDD20;
  font-weight:bold;  
  color: white;
  text-align:center;
}
.comment_time{
  font-size: 10px;
  font-style: italic;
  text-align: right;
  margin-right: 5px;
  margin-top: 2px;
  display: block;
  width: 98%;
}
.comment_body{
  font-size: 12px;
  margin-left: 20px;
  padding-right: 5px;
  word-break: keep-all;
  overflow-wrap: break-word;
}
.comment {
  margin:15px 5px 15px 5px;
}

.comment_deep {
  margin:15px 5px 15px 20px;
}
.comment_del {
  font-size: 12px;
  color: grey;
  font-style: italic;
  right: 15px;
  display: block;
  float: right;
  margin-right: 5px;
  margin-bottom: 15px;
}
.carousel_left{
  opacity:0.5;
  position:absolute;
  display:inline-block;
  left:5px;
  top:45%;
  color: black;
  background-color: white;
  border-radius: 100px;
}
.carousel_right{
  opacity: 0.4;
  position: absolute;
  display: inline-block;
  right: 5px;
  top: 45%;
  color: black;
  background-color: white;
  border-radius: 100px;
}
.carousel_count{
  font-size:18px;
  font-weight:bold;
  color:white;
  padding:5px 10px 5px 10px;
  border-radius:20px;
  background-color:black;
  opacity:0.5;
  position:absolute;
  display:inline-block;
  right:10px;
  bottom:10px;
}

.photo_order{
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 4px;
  background: #fff;
  border: 1px solid #666;
  color: #666;
  text-align: center;
  font: 12px Arial, sans-serif;
  margin-left: 5px;
}
.photo_order_c{
  height:50px;
  width:25px;
  display:inline-block;
  line-height:50px;
}
.p_column{
  display:inline-block;
  font-size:16px;
  font-weight:bold;
  width:100px;
  text-align:right;
}
.bottom_exp {
  font-size:11px;
  display:block;
  margin:0px 0px 10px 0px;
  font-weight:bold;
}
.bottom_exp_select {
  color:#FFDD20;
}
#rlists_row{
  height:75px;
}
#clists_row{
  border-color: lightgrey;
  border-style: solid;
  border-bottom: 1px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
}
#lc_fake{
  width:100%;
  position: relative;
  display:block;
  height: 0px;
  padding-bottom:100%;
  background-clip: content-box;
  box-shadow: inset 0 0 0 200px white;
  border-radius:20px;
  object-fit:cover;
}

.tag_profile_c, .tag_profile_l{
  border-radius: 20px;
  height: 25px;
  margin-right: 3px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-bottom:2px;
  color:grey;
  border-color: grey;
  border-width: 0.5px;
  border-style: solid;
}
.tag_profile_c_select, .tag_profile_l_select{
  background-color: grey;
  border-radius: 20px;
  height: 25px;
  margin-right: 3px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-bottom:2px;
}

.apcount, .apcountl{
  font-size: 10px;
  font-weight: bold;
  margin-right: 1px;
  margin-left: -4px;
  margin-top: auto;
  margin-bottom: auto;
  height: 14px;
  width: 14px;
  background-color: grey;
  display: inline-block;
  color: white;
  text-align: center;
  border-radius: 50%;
  line-height: 14px;
  vertical-align: middle;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid lightgrey;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: lightgrey transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.layer_spinner{
  z-index: 89;
  height: 100%;
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  position: fixed;
  text-align: center;
  line-height: 600px;
  background-color: white;
}

.layer_spinner_pop{
  z-index: 89;
  height: 20%;
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  position: fixed;
  text-align: center;
  background-color: white;
}

.layer_spinner_r{
  z-index: 89;
  height: 100%;
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  position: fixed;
  text-align: center;
  line-height: 600px;
}

.lds-ring-s {
  display: inline-block;
  position: absolute;
  left:30px;
  top:110px;
  width:45px;
  height: 60px;
}

.lds-ring-s div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 8px solid lightgrey;
  border-radius: 50%;
  animation: lds-ring-s 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: lightgrey transparent transparent transparent;
}
.lds-ring-s div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-s div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-s div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring-s {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.layer_comments{
  z-index: 89;
  height: 100vh;
  width: 100%;
  max-width: 400px;
  position: fixed;
  background-color:white;
  box-sizing: border-box;
}

.comments_container {
  width: inherit;
  max-width: 400px;
  margin: 0px auto 0px auto
}

.bold {
  font-weight:bold; 
  text-decoration:underline
}

.hide {
  display:none;
}

.review_vis_container {
  width:48%;
  display:inline-block;
  vertical-align:top
}

.review_vis {
  background-color:#f6f6f6;
  color:grey;
  border-radius:14px;
  font-size:14px;
  font-weight:bold;
  padding: 0px 8px 0px 8px;
  margin:0px auto 6px auto;
  border:0px;
  display: block;
  height:20px;
  text-align:center;
}

.review_vis_select {
  background-color: grey;
  color:white;
}

.review_vis_exp {
  margin: 0px 4px 0px 4px;
}


.elite_post{
  font-size: 12px;
  font-weight:bold;
  background-color: #FFDD20;
  color: white;
  padding: 0px 4px 0px 4px;
  border-radius: 10px;
  margin-top: 6px;
  margin-left: 4px;
}

.elite_profile{
  font-size: 12px;
  font-weight:bold;
  background-color: orange;
  color: white;
  padding: 0px 4px 0px 4px;
  border-radius: 10px;
  margin-left: 8px;
}

.level {
  font-size: 12px;
  font-weight:bold;
  background-color: none;
  color:none;
  padding: 0px 4px 0px 4px;
  border-radius: 10px;
  float: right;
  margin: 3px 0px 0px 3px;
}

.level_post{
  font-size: 12px;
  font-weight:bold;
  background-color:none;
  color:none;
  padding: 0px 4px 0px 4px;
  border-radius: 10px;
  margin-top: 6px;
  margin-left: 4px;
}

.level_profile{
  font-size: 12px;
  font-weight:bold;
  background-color:none;
  color: none;
  padding: 0px 4px 0px 4px;
  border-radius: 10px;
  margin-left: 8px;
}

.rookie {
  background-color:#D3F4FB;
  color:black;
}

.pro {
  background-color:#0091FF;
  color:white;
}

.elite {
  background-color:gold;
  color:white;
}

.hero {
  background-color: #FF8B00;
  color:white;
}

.normal_profile{
  font-size: 12px;
  font-weight:bold;
  background-color: darkgrey;
  color: white;
  padding: 0px 4px 0px 4px;
  border-radius: 10px;
  margin-left: 8px;
}

.id_thumbnail_container {
  display:inline-block;
  overflow:hidden;
}

.lock {
  color: white;
  position: absolute;
  right: 3%;
  top: 3%;
  padding: 1px 3px;
  border-radius: 10px;
}

.multiphotos {
  color:white;
  position:absolute;
  right:6%;
  top:3%;
}

.lock_post {
  color:white;
  position:absolute;
  right:3%;
  top:3%;
  background-color: rgba(0,0,0,0.5);
  padding: 3px 6px;
  border-radius: 10px;
}

.fa-Clone {
  margin-left:3px;
}

.reflink {
  font-size:12px;
  color:blue;
  text-decoration:underline
}

#home_arrow {
  display:inline-block;
  transform: rotate(45deg);
}

.top_banner_button_container {
  text-align: center;
    width: 100%;
    max-width: 600px;
    margin: 0px auto 0px auto;
}


.top_banner_button {
  width:48%;
  margin-right:3px;
  background-color: #f6f6f6;
  font-weight: bold;
  color: black;
  border: 0px;
  padding: 8px;
  border-radius: 20px;
  text-align: center;
  font-size:14px;
  font-family:'NanumSquare'
}

.top_banner_button_select {
  width: 48%;
  margin-right: 3px;
  background-color: #E2E2E2;
  font-weight: bold;
  color: black;
  border: 0px;
  padding: 8px;
  border-radius: 20px;
  text-align: center;
}

.top_reviewers {
  margin: 5px auto 5px auto;
  height: 105px;
  width: 96%;
  max-width: 600px;
}

.introduction_top {
  font-size: 12px;
  height: 48px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lists_row_top{
  vertical-align:middle;
  overflow: scroll;
  white-space: nowrap;
  text-overflow:hidden;
  vertical-align:top;
  scrollbar-width: none;
  display:inline-block;
}

.lists_row_top::-webkit-scrollbar {
  display: none;
}

.starrank{
  width: 100%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
}

.starrank_score{
  margin-left:10px;
  font-size:32px;
  color:#FFDD20;
}

.stats_category{
  display:inline-block;
  width:30%;
  font-weight:bold;
  margin-top:20px;
}

.top_buttons_row {
  height: 100%;
  text-align: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  padding-top:10px;
  padding-bottom:10px;
  z-index: 1;
  background-color: white;
  width: 100%;
  max-width: 600px;
  margin: 0px auto 0px auto;
}

.top_button {
  font-size: 14px;
    font-family: 'NanumSquare';
    width: 55px;
    height: 55px;
    margin: 0px 1.5px 0px 1.5px;
    background-color: #f6f6f6;
    border-width: 0px;
    border-radius: 100%;
    font-weight: bold;
}

.top_button_select {
  font-size: 14px;
  width: 55px;
  height: 55px;
  margin: 0px 1.5px 0px 1.5px;
  background-color: #E2E2E2;
  border-width: 0px;
  border-radius: 100%;
  font-weight: bold;
  color:black;
}

.review_post_target_detail {
  width:80%;
  display:inline-block;
  font-size:24px;
  font-weight:bold;
  overflow:hidden;
  text-overflow:ellipsis;
  padding: 45px 0px 5px 5px;
}

.notification_top {
  width:80%;
  display:inline-block;
  font-size:24px;
  font-weight:bold;
  overflow:hidden;
  text-overflow:ellipsis;
  padding: 5px 0px 5px 5px;
  height:50px;
  line-height:50px
}

.post_top_row_t {
  width:inherit;
  max-width:400px;
  margin:0px auto 0px auto;
  text-align:center;
}

.rating_stars {
  vertical-align: middle;
  margin-bottom: 5px;
  width: 90%;
  padding-left: 10%;
  margin: 0px auto 0px auto;
  text-align: center;
}

.posts_preview_columns_d {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 3px;
  min-width:200px;
  white-space: nowrap;
  text-overflow:clip;
}

.posts_preview_columns_d_new {
  min-width:200px;
  max-width:600px;
  white-space: nowrap;
  text-overflow:clip;
  margin: 0px auto 0px auto;
}

.detail_review_post {
  width: 90%;
  max-width: 600px;
  background-color: #FFDD20;
  font-weight: bold;
  color: white;
  border: 0px;
  padding: 8px;
  border-radius: 20px;
  text-align: center;
  bottom: 20px;
  position: fixed;
  font-size: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.bottom_navigator_d {
  position: fixed;
  height: 80px;
  width: 100%;
  margin: 0px auto 0px auto;
  max-width: 800px;
  bottom: 0px;
  background-color:white;
}

.detail {
  margin-bottom:100px;
}

.newcount {
  background-color:red;
  margin-left:-5px;
  color:white;
}

.notification_line {
  width: 95%;
  max-width: 600px;
  padding: 2%;
  font-weight: bold;
  font-size: 12px;
  height: 80px;
  border-bottom: 1px solid lightgrey;
  vertical-align: middle;
  position: relative;
  margin: 0px auto 0px auto;
  box-sizing: border-box;
}

.notification_line_unchecked {
  background-color:#D3F4FB ;
}
.notification_timestamp {
  font-weight:lighter;
  font-style:italic;
  color:grey;
  display:block;
  float:bottom;
  margin-top:8px;
}

.loader-2 .loader-star {
  position: static;
  width: 60px;
  height: 60px;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
      transform: scale(0.7);
  -webkit-animation: loader-2-star 1s ease alternate infinite;
  animation: loader-2-star 1s ease alternate infinite;
}

.loader-2 .loader-circles {
  width: 8px;
  height: 8px;
  background: #18ffff;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 4px);
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-animation: loader-2-circles 1s ease-in-out alternate infinite;
  animation: loader-2-circles 1s ease-in-out alternate infinite;
}

.container {
  width: 600px;
  margin: 100px auto; 
}
.progressbar {
  counter-reset: step;
  margin-bottom: 50px;
}
.progressbar li {
  list-style-type: none;
  width: 30%;
  float: left;
  font-size: 8px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 20px;
  height: 20px;
  content: counter(step);
  counter-increment: step;
  line-height: 20px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 11px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: #FFDD20;
}
.progressbar li.active:before {
  border-color: #FFDD20;
}
.progressbar li.active + li:after {
  background-color: #FFDD20;
}

.write_top_container {
  width: 100%;
  display: block;
  z-index: 1;
  margin: 20px auto 0px auto;
  max-width: 400px;
}
.my_button_container{
  display:block;
  width:100%;
  height:50%;
  max-width:400px;
  text-align:center;
  margin: 0px auto 0px auto;
}
.my_button {
  height: 120px;
  width: 120px;
  background-color: #FFDD20;
  color:white;
  border-radius: 50%;
  display: inline-block;
  border-width:0px;
  margin:15px;
  font-size:16px;
  font-weight:bold;
}

.my_button_logout {
  background-color: #f6f6f6;
  color:black;
}

.stats_container {
  width: 50%;
  display: inline-block;
  margin-top: 20px;
  height: 120px;
  vertical-align: top;
}

.stats_category, .stats_numbers{
  width:40%;
  margin-top:10px;
  margin-bottom:10px;
  display:inline-block;
}

.stats_numbers{
  font-size:24px;
  color:black;
  font-weight:bold;
}

.stats_save{
  font-size:24px;
  color:black;
  font-weight:bold;
}

.stats_bar{
  color:black;
  background-color:#E2E2E2;
  width:92%;
  margin:0px auto 0px auto;
  height:25px;
  text-align:center;
  line-height:25px;
  border-radius:12px;
  font-weight:bold;
}

#button_mypage {
  background-color: #E2E2E2;
  color:black;
}

#pick_score{
  color:#FFDD20;
  font-weight:bold;
}

.top_reviewers_container {
  margin-bottom:90px;
}

.fa-chevron-left, .fa-chevron-right, .fa-share-square {
  width: 21px !important;
  height: 21px !important;
}

#pexp{
  text-align:left;
  padding-left:30px;
  padding-right:5px;
  line-height:20px;
  word-break:keep-all;
}
.layertext {
  display:block;
  height:100px;
}
.comments_notification{
  width:90%;
  margin:0px auto 0px auto;
  height:50px;
  border-color:grey;
  border-width:1px;
  border-style:solid;
  vertical-align:top;
}
.comments_notification_text{
  width: 90%;
  display: inline-block;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.about_body{
  background-color:#eeeff5;
}

.about_form{
  width:100%;
  max-width:400px;
  margin:0px auto 0px auto;
}

.about_image {
  width:100%;
  object-fit:cover;
  overflow:hidden;
  margin-bottom:20px
}

.about_image_2 {
  width:100%;
  object-fit:cover;
  overflow:hidden;
  margin-top:20px;
  margin-bottom:20px
}

.about_image_2_container {
  width:95%;
  justify-content:center;
  margin:auto;
  object-fit:cover;
  overflow:hidden;
}

.about_image_3 {
  width:100%;
  object-fit:cover;
  overflow:hidden;
  margin-top:10px;
  margin-bottom:200px
}

#pickgle_contact {
  color:white;
}

.contact_button {
  width: 80%;
  max-width: 400px;
  height: 50px;
  text-align: center;
  font-weight: bold;
  border-width: 0px;
  margin: 0px 40px 0px 40px;
  color: black;
  background-color: lightgrey;
  font-size: 16px;
  border-radius: 15px;
}

.write_example{
  font-style:italic;
  font-weight:normal;
}

.posts_preview_columns_p_new {
  min-width:200px;
  max-width:600px;
  white-space: nowrap;
  text-overflow:clip;
  margin: 20px auto 80px auto;
}

.posts_preview_p_new {
  width: 96%;
  min-width: 125px;
  max-width: 600px;
  margin: 0px auto 0px auto;
  padding: 0px;
  height: 110px;
  vertical-align: bottom;
  border-bottom: 1px solid lightgrey;
}

.posts_preview_p_new_sv {
  width: 96%;
  min-width: 125px;
  max-width: 600px;
  margin: 0px auto 0px auto;
  padding: 0px;
  vertical-align: bottom;
  border-bottom: 1px solid lightgrey;
}

.review_left_pane{
  width:73%;
  display:inline-block;
  height:100%;
}

.post_thumbnail_new {
  display:inline-block;
  width:27%;
  height:27%;
  margin-right:2px;
  vertical-align:top;
  position:relative;
}

.review_target_preview_new {
  font-size: 16px;
    width: 72%;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    display: inline-block;
    vertical-align: top;
}

.review_target_container {
  width: 100%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin: 2px 10px 6px 4px;
  text-align: left;
  vertical-align: top;
}

.review_target_container_new {
  width: 73%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin: 2px 10px 6px 4px;
  text-align: left;
  vertical-align: top;
}

.rating_new {
  font-size: 18px;
  width:28%;
  color: #FFDD20;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}

.post_thumbnail_pic_new {
  width: 100%;
  height: 100%;
  border-color: gray;
  border-width: thin;
  border-radius: 10px;
  position: absolute;
  object-fit: cover;
}

.lt_container_row_new{
  width:95%;
  overflow: scroll;
  text-overflow: hidden;
  white-space: nowrap;
  height:30px;
  scrollbar-width:none;
  margin:3px 3px 3px 3px;
  display:inline-block;
}

.lt_container_row_new::-webkit-scrollbar {
  display: none;
}

.post_link_wrap_p {
  vertical-align:top;
  margin:15px 0px 0px 0px;
}

.post_link_wrap {
  vertical-align:top;
}


.post_link_wrap_sv {
  vertical-align:top;
  padding-bottom:20px;
}

.review_target_text_new{
  width: 95%;
  margin: 3px 3px 3px 3px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification_container {
  width:80%;
  display:inline-block;
}

.notification_photo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  float: right;
  border-radius: 10px;
}

.slider {
  height:20px;
  line-height:20px;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 0px 0px 8px;
}

.exp_text {
  color:white;
  position: fixed;
  display: block;
  bottom: 80px;
  height:270px;
  width:100%;
  max-width:400px;
  background-color: #eeeeee;
  z-index: 250;
}

.write_forms_buttons {
  position: fixed;
  display: block;
  bottom: 0px;
  height:80px;
  width:100%;
  max-width:400px;
  background-color: #eeeeee;
  z-index: 250;
  margin: auto;
}

.write_forms_buttons2 {
  position: fixed;
  display: block;
  bottom: 0px;
  height:140px;
  width:100%;
  max-width:400px;
  background-color: #eeeeee;
  z-index: 250;
  margin: auto;
}

.about_button {
  width:45%;
  max-width:400px;
  height:50px;
  text-align:center;
  font-weight:bold;
  border-width:0px;
  color:white;
  background-color:lightgrey;
  font-size:16px;
  border-radius:15px;
  align-self: center;
  float:left;
  margin:2.5%;
  vertical-align: middle;
  line-height: 50px;

}

.about_button_focus {
  width:45%;
  max-width:400px;
  height:50px;
  text-align:center;
  font-weight:bold;
  border-width:0px;
  color:white;
  background-color:#FFBD00;
  font-size:16px;
  border-radius:15px;
  align-self: center;
  float:left;
  margin:2.5%;
  vertical-align: middle;
  line-height: 50px;

}

.about_button_focus_2 {
  width:95%;
  max-width:400px;
  height:55px;
  text-align:center;
  font-weight:bold;
  border-width:0px;
  color:white;
  background-color:#FFBD00;
  font-size:20px;
  border-radius:15px;
  align-self: center;
  float:left;
  margin:2.5% 2.5% 0.5% 2.5%;
  vertical-align: middle;
  line-height: 50px;

}

.about_logo {
  width:20%;
  margin-right:5px;
  vertical-align:middle;
}

.web_profile {
  width:100%;
  height:100%;
  max-width:550px;
  background-color: #f3f3f8;
  scrollbar-width:none;
  -ms-overflow-style: none;
  overscroll-behavior: none;
  margin: 0 auto; 
  
}
.web_profile::-webkit-scrollbar {
  display:none;
}
.web_profile_panel {
  width:100%;
  height:170px;
  border-bottom: lightgrey;
  border-bottom-width: 1;
  padding-top:20px;
}
.web_info_panel {
  height:95px;
  flex-direction: row;
  margin-left:calc(min(20px, 1vw));
  width: calc(min(100vw, 550px) - min(42vw + 15px, 160px));
  float:right;
  margin-top:15px;
  text-overflow: ellipsis;  
}
.web_profile_image {
  height:calc(min(120px, 40vw));
  width:calc(min(120px, 40vw));
  margin-left:15px;
  margin-top:15px;
  border-radius: 15px;
  background-color: #f4f4f4;
  object-fit:cover;
  float:left;
}

.web_profile_info {
  width: calc(min(100%, 550px) - 125px - 6%); 
  height: 85px;
  text-overflow: ellipsis;  
}

.web_profile_name {
  flex-direction: column;
  margin-left:3px;
}

.web_nickname {
  font-size:18px;
  font-weight: bold;
  color:black;
  width:100%;
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;  
  width: 200px;
}

.web_intro {
  color:black;
  font-size:13.5px;
  margin-top:5px;
  width:'100%';
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;  
  width: 200px;
}

.web_profile_level {
  width:'100%';
  align-content: space-between;
  flex-direction: row;
  height:20px;
  margin-top:40px;
}

.web_level_button_row {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
  width: 100px;
  height: 25px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  background-color: white;
  text-align: center;
  line-height: 25px;
}

.web_level_text {
  font-size:13px;
  color:black;
}

.web_badge_count {
  font-size:17px;
  font-weight:700;
  margin-left:5px;
}
.web_badge {
  padding-bottom:4px;
  margin-right:5.5px;
}

.web_post_preview {
  width: calc(min(550px*0.49, 49vw)); 
  height: calc(min(550px*0.41, 41vw)); 
  margin-bottom: calc(min(100%, 550px) * 0.006333); 
  border-radius: 0;
  align-self: center;
  background-color: white;
  margin-right: calc(min(100%, 550px) * 0.006333) ;
  background-size: cover;
  background-position: center;
}

.web_post_preview2 {
  width: calc(min(550px*0.49, 49vw)); 
  height: calc(min(550px*0.41, 41vw)); 
  margin-bottom: calc(min(100%, 550px) * 0.006333); 
  border-radius: 0;
  align-self: center;
  background-color: black;
  z-index:99;
  margin-right: calc(min(100%, 550px) * 0.006333) ;
}

.web_bg_image {
  top:0px;
  width: 100%; 
  height: 100%;
  object-fit:contain;

}
.web_content_row {
  width: 90%;
  height: 100%;
  align-items: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left:5%;
  z-index:10;
}

.web_left_panel {
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  height: 80%;
  margin-left: 5px;
  align-content: center;
  align-items: center;
}

.web_profile_list_title_w {
  font-size: 17px;
  width: 93%;
  font-weight: 700;
  padding-top:20%;
  color: white;
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;  
}

.web_profile_list_title {
  font-size: 17px;
  width: 93%;
  font-weight: 700;
  padding-top:20%;
  color: black;
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;  
}

.web_lower_container {
  flex-wrap: wrap;
  flex-direction: row;
  height: 10%;
  width:100%;
}

.web_stats_white {
  color:#DFDFDF;
  margin-right:4px;
}

.web_stats_black {
  color:#606060;
  margin-right:4px;
}

.web_stats_text_white {
  font-size:13px;
  margin-right:5px;
  color:#dfdfdf;
}

.web_stats_text_black {
  font-size:13px;
  margin-right:5px;
  color:#606060;
}

.web_stats_row {
  margin-left:5px;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
  align-content: center;
  height:100%;
  width:100%;
  line-height: 100%;
}

.web_profile_list_container {
  width: calc(min(100%, 550px) * 0.993667);
  max-width: 550px;
  flex-direction: row;
  flex-wrap:wrap;
  display: flex;
  background-color:#f3f3f8;
  column-count:2;
  padding-top: calc(min(100%, 550px) * 0.006333); 
  padding-left: calc(min(100%, 550px) * 0.006333);
  padding-bottom: 100px
}

.web_floating_action_button_container {
  position: fixed;
  bottom: 0;
  height: 150px;
  width: 99.5%;
  max-width:550px;  
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 99;
  background-color: white;
  border-color: lightgrey; /* Corrected border color value */
  border-top: 1px solid lightgrey; /* Border for the top */
  display: flex;
  justify-content: center;
  align-items: center; /* Removed single quotes around center */
  align-content: center; /* Removed single quotes around center */
  flex-direction: column;
}

.web_floating_action_button_text {
  height: 20px;
  width: 90%;
  font-size:14px;
  color:black;
  font-weight:600;
  margin: 10px 0px 20px 0px;
  align-self:center;
  justify-content: center;
  align-items: center;
  text-align:center
}

.web_floating_button {
  width:90%;
  height:50px;
  align-self: center;
  border-width: 0px;
  background-color: #ffd100;
  font-size:16px;
  font-weight:bold;
  color:black;
  align-items: center;
  align-content: center;
  border-radius:10px;
}

.web_list_top_panel {
  width:100%;
  max-width:550px;
  height:260px;
  background-color: #f3f3f8;
  border-bottom-width:0.5px;
  border-bottom-color:grey;
}

.web_list {
  width:calc(min(100vw, 550px));
  height:100%;
  background-color: #f3f3f8;
  margin: 0 auto; 
  overflow-y:scroll;
  scrollbar-width:none;
  -ms-overflow-style: none;
  
  /*
  Below properties mess up with top panel components?
  overscroll-behavior: none;
  overflow-x:hidden;
  */
}

.web_list::-webkit-scrollbar {
  display:none;
}

.web_list_panelcomponent {
  width:'100%';
  height:'100%';
  flex-direction: column;
  position:absolute;
  top:0;
}

.web_list_id_row {
  width:calc(min(95vw, 500px));
  margin-top:20px;
  height:30px;
  display:flex;
  align-items:center;
  margin-left:20px;
  line-height:30px;
  align-content: center;
}

.web_list_profile_thumbnail {
  width:20px;
  height:20px;
  border-radius:20px;
  object-fit: cover;
}

.web_list_nickname {
  font-size:14px;
  margin-left:4px;
}

.web_list_username {
  font-size:14px;
  margin-left:4px;
  font-weight:'300'
}

.web_list_nickname_w {
  font-size:13px;
  margin-left:4px;
  color:white
}

.web_list_username_w {
  font-size:13px;
  margin-left:2px;
  font-weight:'300';
  color:white
}

.web_list_listname {
  height:35px;
  margin-top:3px;
  margin-left:20px;
  padding-top:10px;
  font-size:22px;
  font-weight:bold;
  width:95%
}

.web_list_listname_w {
  height:35px;
  margin-top:3px;
  margin-left:20px;
  padding-top:10px;
  font-size:22px;
  font-weight:bold;
  width:95%;
  color:white
}

.web_list_listexp {
  height:30px;
  margin-top:5px;
  margin-left:20px;
  font-size:13.5px;
  width:95%;
}

.web_list_listexp_w {
  height:30px;
  margin-top:5px;
  margin-left:20px;
  font-size:13.5px;
  width:95%;
  color:white;
}

.web_list_stats_row {
  position:absolute;
  top:195px;
  height:45px;
  width:96%;
  margin-left:15px;
  flex-direction:row;
}

.web_list_stats_row_w {
  position:absolute;
  top:195px;
  height:45px;
  width:96%;
  margin-left:15px;
  flex-direction:row;
}

.web_list_stats_button {
  width:180px;
  background-color: white;
  border-radius: 12px;
  height:50px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.web_list_stats_button_w {
  width:180px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 12px;
  height:50px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.web_list_stats_text {
  font-size:14.5px;
  justify-content: center;
  justify-content:center;
  align-items: center;
  align-content: center;
}

.web_list_stats_text_w {
  font-size:14.5px;
  color:white;
  justify-content:center;
  align-items: center;
  align-content: center;
  
}

.web_list_stats_text2 {
  font-size:20px;
  font-weight:600;
  justify-content:center;
  align-items: center;
  align-content: center;
}

.web_list_stats_text2_w {
  font-size:20px;
  font-weight:600;
  color:white;
  justify-content:center;
  align-items: center;
  align-content: center;
}

.web_list_stats_text_button {
  width:60px;
  height:50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content:center;
  display:flex;
}

.web_list_stats_divider {
  background-color: grey;
  width:1px;
  height:80%;
}

.web_list_bottom_post {
  height:100%;
  width:100%;
  max-width: 550px;
  background-color: #f3f3f8;
  padding-top:20px;
  overflow: auto;
}

.web_list_post_preview {
  width:95%;
  margin-left:2.75%;
  min-width: 125px;
  height:120px;
  margin-bottom:25px;
  align-items: center;
  align-self: center;
  align-content:center;
  justify-content: center;
  background-color: white;
  border-radius:15px;
  flex-direction: row;
  display: flex;
}


.web_list_left_panel {
  height:100%;
  width:74%;
  align-self:flex-start;
  border-right-width:0.3px;
  border-right-style:solid;
  border-right-color:lightgrey;
  padding-left:5%;
}

.web_list_review_thumbnail {
  width:26%;
  height:100%;
  background-color:#f4f4f4;
  object-fit:cover;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.web_list_text_panel {
  height: 65%;
  padding-top:10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  display:flex;
}

.web_list_review_target {
  font-size:17px;
  font-weight:700;
  width:calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis; 
  max-height:2em;
  line-height:1em;
}

.web_list_rating {
  font-size:17px;
  color:#ffc900;
  font-weight: 600;
  align-items: center;
  align-content: center;
  flex-direction: row;
  text-align: center;
  width:50px;
  padding-right:10px;
  justify-content: flex-end;
}

.web_list_tag_row {
  flex-direction: row;
  width:95%;
  align-items: center;
  align-content: center;
  margin-top:6px;
  display:flex;
  max-height:1em;
  line-height:1em;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.web_list_tag {
  font-size:12.5px;
  color:#6c6c6c;
  margin-right:3px;
  max-height:1em;
  line-height:1em;
}

@media (max-width: 260px) {
  .web_info_panel {
    visibility: hidden;
  }
}

.web_post {
  width:calc(min(100vw, 550px));
  height:100vh;
  background-color: #f3f3f8;
  margin: 0 auto; 
  overflow-y:scroll;
  scrollbar-width:none;
  -ms-overflow-style: none;
  
  /*
  Below properties mess up with top panel components?
  overscroll-behavior: none;
  overflow-x:hidden;
  */
}

.web_post::-webkit-scrollbar {
  display:none;
}

.web_post_review_target_row {
  padding-left:15px;
  height:35px;
  align-items: center;
  flex-direction: row;
  display:flex;
  margin-top:10px;
}

.web_post_review_target {
  font-size: 22px;
  font-weight:bold;
  width:calc(min(550px, 100%) - 90px);
  white-space: nowrap;     
  overflow: hidden;     
  text-overflow: ellipsis; 
}

.web_post_rating {
  flex-direction: row;
  align-items: center;
  width:70px;
  align-items: center;
  align-content: center;
  float: right;
  padding-right:15px;
  text-align: right;
}

.web_post_rating_text {
  font-size: 22px;
  color:#ffc900;
  font-weight:600;
}

.web_post_review_content {
  margin-top:20px;
  padding-left:15px;
  padding-right:15px;
  font-size:15px;
  margin-bottom:300px;
}

.web_post_tags_row {
  margin-top:20px;
  width:100%;
  padding-left:15px;
  padding-right:15px;
  height:40px;
  flex-direction:'row';
  display: 'flex';
  white-space: nowrap;     
  overflow: hidden;     
  text-overflow: ellipsis; 
}

.web_post_list_tag {
  font-weight: bold;
  display: inline-flex; /* Use inline-flex to make it adjust to content width */
  align-items: center;
  border-radius: 20px;
  font-size: 20px;
  margin-right: 8px;
  margin-bottom: 1px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px;
  font-size: 14.5px;
  background-color: #dddee8;
}

.web_post_id_row {
  width:calc(min(100vw, 500px));
  margin-top:20px;
  height:30px;
  display:flex;
  align-items:center;
  margin-left:15px;
  line-height:30px;
  align-content: center;
}